import { render, staticRenderFns } from "./Introduction.vue?vue&type=template&id=18e76815&scoped=true&"
import script from "./Introduction.vue?vue&type=script&lang=js&"
export * from "./Introduction.vue?vue&type=script&lang=js&"
import style0 from "./Introduction.vue?vue&type=style&index=0&id=18e76815&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e76815",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/nantong-hrpark-prod-mobile-company(微信公众号-企业）/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('18e76815', component.options)
    } else {
      api.reload('18e76815', component.options)
    }
    module.hot.accept("./Introduction.vue?vue&type=template&id=18e76815&scoped=true&", function () {
      api.rerender('18e76815', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/park/Introduction.vue"
export default component.exports